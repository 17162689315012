<template>
    <div class="px-8 mt-4">
      <v-dialog
          v-model="poViewItemDialog"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          class="poModal"
          scrollable
        >
          <v-card>
            <v-toolbar
              dark
              color="green darken-2"
              max-height="65"
            >
              <v-toolbar-title>{{ poData.POCode }}</v-toolbar-title>
                <v-spacer></v-spacer>
              <v-btn
                icon
                dark
                @click="clickCloseMainDialog()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="poData.FinanceStatusID == 7"
              >
              <span class="text-no-wrap">Funds Availability</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(poData.FinanceApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="poData.PresidentStatusID == 7"
                step="2"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">College President Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(poData.PresidentApprovedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
          </div>
        <v-card-text style="max-height: 900px">
        <!-----Header----->
          <div>

            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                 <v-subheader class="font-weight-bold text-h6">Purchase Order</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Entity Name</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>Davao del Norte State College</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">PO no.</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.POCode}}</v-subheader>
              </v-col>
            </v-row>

              <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Supplier</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.CompanyName}}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Place of Delivery</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.podDescription}}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Supplier Address</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.CompanyAddress}}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Date of Delivery</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.dodDescription}}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">TIN</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.TIN}}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Delivery Term</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{ poData.dtDescription }}</v-subheader>
              </v-col>
            </v-row>

            <v-row no-gutters class="mb-5">
              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Mode of Procurement</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.mopDescription}}</v-subheader>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <v-subheader class="font-weight-bold">Payment Term</v-subheader>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-subheader>{{poData.ptDescription}}</v-subheader>
              </v-col>
            </v-row>
          </div>

            <v-row>
              <v-col cols="12">
                <v-card class="" >
                  <v-data-table
                  :loading="loading"
                  :headers="headers"
                  :items="itemList"
                  :options.sync="options"
                  :server-items-length="totalCount"
                  :footer-props="footerProps"
                  class="elevation-1"
                >
    <template v-slot:top>
      <v-toolbar flat>
          <span class="green--text text--darken-2">List Of Items</span> 
        <v-spacer></v-spacer>
            <v-btn
             outlined
              color="red darken-2"
              dark
              small
              @click="print(poData)"
              class="mr-2"
            >
              <v-icon>print</v-icon>
              Print
            </v-btn>

                    <v-btn
        color="green darken-2"
        dark
        outlined
        elevation="2"
         v-if="$store.state.user.roleID == 9 || $store.state.user.roleID == 8"
        small
        class="mr-2"
        @click="approveItem(poData)"
      >
        Approve
      </v-btn>

                  <v-btn
        color="red darken-2"
        dark
        outlined
        elevation="2"
        class="mr-2"
        small
        v-if="$store.state.user.roleID == 9 || $store.state.user.roleID == 8"
        @click="pendingItem(poData)"
      >
        Pending
      </v-btn>
      </v-toolbar>
    </template>
        <template v-slot:[`item.UnitCost`]="{ item }">
          <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost || 0) }}</span>
        </template>
        <template v-slot:[`item.Amount`]="{ item }">
          <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.Qty))}}</span>
        </template>
                </v-data-table>
                </v-card>
              </v-col>
            </v-row>
        </v-card-text>
          </v-card>
        </v-dialog>
                <v-dialog
          v-model="dialogPending"
          persistent
          max-width="450px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">This Purchase Order is Pending</span>
              <v-spacer></v-spacer>
              <v-btn
                    icon
                    dark
                    color="secondary2"
                    @click="dialogPending = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
            </v-card-title>
              <v-form @submit.prevent ref="formRef">
            <v-card-text>
              <v-container>
              <v-row>
              <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                     <v-textarea
                  color="green"
                    dense
                       v-model="remarks"
                     :rules="[formRules.required]"
                      label="Remarks"
                     counter
                     maxlength="500"
                    ></v-textarea>
        </v-col>
                </v-row>
              </v-container>
            </v-card-text>
             </v-form>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogPending = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                 @click="confirmReturn()"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogApprove" max-width="500px">
          <v-card>
            <v-card-title class="text-h6">Are you sure you want to approve this PO?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogApprove = false">Cancel</v-btn>
              <v-btn color="green darken-2" @click="confirmApprove()" text>Yes</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </div>
</template>
    
    <script>
      export default {
	props : {
        poData: {},
	},
        data: () => ({
          remarks: null,
          headers: [
              {
                text: 'Item Name',
                align: 'start',
                sortable: false,
                value: 'ItemName',
              },
              { text: 'Description', value: 'ItemDescription' , sortable: false },
              { text: 'Quantity', value: 'Qty', align: "center", sortable: false },
              { text: 'Unit Meas.', value: 'UnitMeasureDescription', align: "center", sortable: false},
              { text: 'Unit Cost', value: 'UnitCost', align: "end", sortable: false},
              { text: 'Amount', value: 'Amount', align: "end", sortable: false}
            ],
          loading: false,
          poViewItemDialog: false,
           footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
          totalCount: 0,
           options: {},
          isLoading: false,
          itemList: [],
          item: null,
          dialogPending: false,
          dialogApprove: false,
        }),
       watch: {
          options: {
            handler () {
            if(this.totalCount > 0){
             this.getItems();
                }
            },
            deep: true,
          },
            poData: {
            handler (data) {
            if(data.POID){
            this.poViewItemDialog = true;
            this.getItems();
            }
            },
            deep: true,
          },
        },
        methods: {
                approveItem(data) {
        this.item = data;
          this.dialogApprove = true;
      },
          pendingItem(data) {
          console.log(data);
          this.item = data;
          this.dialogPending = true;
      },
      confirmReturn() {
      if(this.$refs.formRef.validate()){
          let data = new FormData();
              data.append('Remarks', this.remarks)
              data.append('POID', this.item.POID);
              this.axiosCall(
              '/signatories/po/pending',
              'POST',
              data
          )
          .then((res) => {
            console.log(res)
             this.clickCloseMainDialog();
          })
      }
      },
            confirmApprove() {
          let data = new FormData();
              data.append('POID', this.item.POID);
              this.axiosCall(
              '/signatories/po/approve',
              'POST',
              data
          )
          .then((res) => {
            console.log(res)
            this.clickCloseMainDialog();
          })
      },
            clickCloseMainDialog () {
                this.dialogPending = false;
                this.dialogApprove = false;          
                this.totalCount = 0;
                this.poViewItemDialog = false;
                this.eventHub.$emit('closePOItemodal', false);
            },
          print (data) {
             window.open(this.apiUrl + "/po/report/" + data.POID);
          },
          getItems () {
            let data = new FormData();
             if(this.options.page){
                  data.append('page', this.options.page);
                  data.append('itemsPerPage', this.options.itemsPerPage);
             }else{
                  data.append('page', 1);
                  data.append('itemsPerPage', 10);
             }
               data.append('POID', this.poData.POID);
                  this.axiosCall(
                  '/po/ViewItems',
                  'POST',
                  data
              )
              .then((res) => {
                this.loading = false;
                  this.itemList = res.data.data.result;
                  this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
                  this.ppmpUsedAmount = res.data.data.useAmount;
              })
          }
        },
      }
    </script>
        
    <style scoped>
    .v-dialog--fullscreen, .poModal {
      overflow-x: hidden !important;
    }
    </style>